import React, { useEffect } from 'react';
import { graphql } from 'gatsby';

import { useAppContext } from '../state';
import { QueryFragments } from '../GraphQl/queryFragments'; // eslint-disable-line

import { PageMeta } from '../components/pageMeta';
import BlockZone from '~blockZone';

const IndexPage = ({ data: { page } }) => {
  const { reverseHeader, solidHeader, meta, pageBlocks } = page || {};
  const { setReverseNav, setHideFooter, setSolidHeader } = useAppContext();

  useEffect(() => {
    document.documentElement.classList.remove('post');
    document.documentElement.classList.remove('themeDark');
    setReverseNav(reverseHeader);
    setSolidHeader(solidHeader);
    setHideFooter(false);
  }, [reverseHeader, setReverseNav, setHideFooter, solidHeader]);

  return (
    <>
      <PageMeta {...meta} />
      {pageBlocks && <BlockZone {...pageBlocks} />}
    </>
  );
};

export default IndexPage;

export const pageQuery = graphql`
  query homePageQuery {
    page: sanityHomePage {
      reverseHeader
      solidHeader
      meta {
        ...MetaCardFields
      }
      pageBlocks {
        ...BlockZone
      }
    }
  }
`;
